import React, {useEffect, useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import { Fragment } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {MiniChart } from "react-ts-tradingview-widgets";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WalletButton from '../Wallet/WalletButton';
import { useSelector } from 'react-redux';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';
import MobileMenu from './MobileMenu';
import Cards from '../Cards/Cards';

const CoinMarket = () => {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const coins = useSelector((state) => state.coins.coins);
    const coinRates = useSelector((state) => state.coins.coinRates);
    const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
    const availableCoins = ['USDT', 'USDTERC20', 'USDTTRC20', 'XMR', 'XLM', 'ETH', 'TRX', 'XRP', 'BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB'];
    const fundings = useSelector((state) => state.wallet.fundings);
    

    const calculate24hrChange = (coin) => {
      let rate = 0;
      coinRates.forEach(coinRater => {
        if (coinRater.symbol === coin) {
          rate = parseFloat(coinRater.changePercent24Hr);
        }
      });

      return rate;
    }

    const calculateBalance = (coin) => {
      let amount = 0;
      fundings.forEach(fund => {
        if (fund.gateway === coin) {
          amount += parseFloat(fund.amount);
        }
        });
      
        return amount;
    }
  return (
    <div>
      {/* <Cards/> */}
      
      <Card>
        <CardActions sx={{justifyContent: 'space-between', paddingX: '15px'}}>
        <WithdrawalButton/>
        <WalletButton/>        

        </CardActions>
      </Card>

      {/* <MobileMenu/> */}

      {/* <EarningAnalysis/> */}

        {coinRates.length < 1 &&
        <CircularProgress/>
        }
        {coinRates.length > 0 && 

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

            {coinRates.map((coin) =>(
              availableCoins.includes(coin.symbol) &&
                <Fragment key={coin.symbol}>
                <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}
                onClick={()=>setCoinSymbol('BINANCE:'+coin.symbol+'USD')}
                >
          <ListItemAvatar>
            <Avatar alt="Coin Name"
            src={
              
              coin.symbol === 'XLM' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1697094437/XLM-logo.png' ||
              coin.symbol === 'ETH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1697051684/ETH.png' ||
              coin.symbol === 'XRP' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1697049471/xrp_coin.png' ||
              coin.symbol === 'TCN' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451074/242cd1653295679.png' ||
              coin.symbol === 'BTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/btc-sw.png'||
              coin.symbol === 'LTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450859/ltc-sw.png'||
              coin.symbol === 'BCH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/bch-sw.png'||
              coin.symbol === 'DOGE' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/doge-sw2.png'||
              coin.symbol === 'DASH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451267/ba8ea1653295812.png'||
              coin.symbol === 'USDT' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554232/usdttrc20.png'||
              coin.symbol === 'USDTERC20' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554186/usdterc20.png'||
              coin.symbol === 'TRX' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554250/trx.png'||
              coin.symbol === 'XMR' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554258/xmr.png'||
              coin.symbol === 'BNB' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451323/ac2f71653295852.png'
            }
            />
        </ListItemAvatar>
                <ListItemText
                  primary={coin.symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {coin.name}
                      </Typography>
                    </React.Fragment>
                  }
                />

                <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Typography mx={1}>
                    {formatPrice(coin.priceUsd)}
                    </Typography>

                    <Typography mx={1} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    
                    <Typography style={{fontWeight: 'lighter', color: `${calculate24hrChange(coin.symbol) > 0 ? '#13e576' : '#e8073c'}`}} variant="caption" display="block" gutterBottom>
                          {calculate24hrChange(coin.symbol).toFixed(2)}
                        </Typography>
                     <Typography style={{marginLeft: '2px'}} variant="caption" display="block" gutterBottom>
                          24hr
                        </Typography> 
                    </Typography>
                </Box>

                <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Typography mx={1} sx={{fontWeight: 'bolder'}}>
                    {/* {formatPrice(coin.priceUsd)} */}
                    {formatPrice(calculateBalance(coin.symbol))}
                    </Typography>

                    <Typography mx={1} variant="caption">
                    {(calculateBalance(coin.symbol)/coin.priceUsd).toFixed(8)}
                    </Typography>
                </Box>
                
              </ListItem>
              <Divider variant="inset" component="li" />
              </Fragment>
            ))}
        
        
  
      </List>
        
        }
      
      
    </div>
  )
}

export default CoinMarket
