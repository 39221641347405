import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from "react-query";
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import apiClient from "../../request/http-common";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import SendIcon from '@mui/icons-material/Send';

const WithdrawalButton = () => {
    const user = useSelector((state) => state.user.user);
    const coins = useSelector((state) => state.coins.coins);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const addresses = useSelector((state) => state.coins.addresses);

    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const coinRates = useSelector((state) => state.coins.coinRates);

    const [error, setErros] = useState('');
    const [amount, setAmount] =useState(0);
    const [coinName, setCoinName] =useState('');
    const [address, setAddress] =useState('');
    const [source, setSource] =useState('');

    const fundings = useSelector((state) => state.wallet.fundings);

    const calculateBalance = (coin) => {
      let amount = 0;
      fundings.forEach(fund => {
        if (fund.gateway === coin) {
          amount += parseFloat(fund.amount);
        }
        });
      
        return amount;
    }
  
    
    const [barstate, setBarState] = useState({
        top: false,
        bottom: false,
        bottom: false,
        right: false,
      });


    const togglebottomBar = () => {
        const event = new Event("toggleWithdrawalBar");
        window.dispatchEvent(event);
      }

      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setBarState({ ...barstate, [anchor]: open });
      };


      const { isLoading: isWithdrawing, mutate: postWithdrawal } = useMutation(
        async () => {
          return await apiClient.post(`/api/withdraw`, {
            amount: amount,
            coin_name: coinName,
            address: address, 
            source: source

          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
              }            
          },
          onError: (err) => {
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }
                

                
          },
        }
      );


      useEffect(() => {
        window.addEventListener(
          "toggleWithdrawalBar",
          (e) => {
           setBarState({ ...barstate, ['bottom']: true });
          },
          false
        );
        
      }, []);

  return (
    <div>
        <Button onClick={()=>togglebottomBar()} variant="contained" color='primary' startIcon={<SendIcon />}>
            Send
        </Button>

      <SwipeableDrawer
      
      anchor={`bottom`}
      open={barstate[`bottom`]}
      onClose={toggleDrawer(`bottom`, false)}
      onOpen={toggleDrawer(`bottom`, true)}> 

      <Card sx={{ borderRadius: '9px', height: '100%'}}>

      <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
      Fill the form below to withdraw your funds
      </Typography>

          <CardContent>

            <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
            <InputLabel htmlFor="amount">Amount(USD)</InputLabel>
            {(error !== '' && error.amount) ?

            <OutlinedInput
            onChange={(e)=>setAmount(e.target.value)}
            error
            id="amount"
            type={'number'}
            name="amount"
            label={`Amount (USD)`}
            helperText={error.amount}
            />
            :
            <OutlinedInput
            onChange={(e)=>setAmount(e.target.value)}
            id="amount"
            type={'number'}
            name="amount"
            label={`Amount (USD)`}
            />
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.amount}
            </Typography>
        </FormControl>

        {/* {addresses.length > 0 &&
        <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
        <InputLabel htmlFor="coinName">Select Coin</InputLabel>
        {(error !== '' && error.coinName) ?
            <Select
                error
                onChange={(e)=>setCoinName(e.target.value)}
                labelId="coinName"
                id="coinName"
                name="coinName"
                label={`Select Coin`}
                helperText={error.coinName}
                >
                <MenuItem selected value=''>
                    Select Coin
                </MenuItem> 
                {addresses.map(coin =>(
                    <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
                ))}             
                
            </Select>
        :
            <Select
                onChange={(e)=>setCoinName(e.target.value)}
                labelId="coinName"
                name="coinName"
                id="coinName"
                label={`Select Coin`}
                >
                <MenuItem selected value="">
                    Select Coin
                </MenuItem>              
                {addresses.map(coin =>(
                <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
                ))} 
            </Select>
        }
        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
        {error !== '' && error.coinName}
        </Typography>
    </FormControl>
        } */}


<FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
        <InputLabel htmlFor="source">Withdrawal Source</InputLabel>
        {(error !== '' && error.source) ?
            <Select
                error
                onChange={(e)=>setSource(e.target.value)}
                labelId="source"
                id="source"
                name="source"
                label={`Withdrawal Source`}
                helperText={error.source}
                >
                <MenuItem selected value=''>
                    Withdrawal Source
                </MenuItem> 
                {coinRates.length > 0 && 
                  coinRates.map((coin) =>(
                    <MenuItem key={coin.symbol} selected value={coin.symbol}>
                    {coin.symbol}
                    </MenuItem> 
                  ))}            
                
            </Select>
        :
            <Select
                onChange={(e)=>setSource(e.target.value)}
                labelId="source"
                name="source"
                id="source"
                label={`Withdrawal Source`}
                >
                <MenuItem selected value="">
                    Withdrawal Source
                </MenuItem>   

                {coinRates.length > 0 && 
                  coinRates.map((coin) =>(
                    <MenuItem key={coin.symbol} selected value={coin.symbol}>
                    {coin.symbol} ({coin.name}) (bal = {formatPrice(calculateBalance(coin.symbol))})
                    </MenuItem> 
                  ))} 
            </Select>
        }
        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
        {error !== '' && error.source}
        </Typography>
    </FormControl>

<FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
            <InputLabel htmlFor="address">Wallet Address</InputLabel>
            {(error !== '' && error.address) ?

            <OutlinedInput
            onChange={(e)=>setAddress(e.target.value)}
            error
            id="address"
            type={'text'}
            name="address"
            label="Wallet Address"
            helperText={error.address}
            />
            :
            <OutlinedInput
            onChange={(e)=>setAddress(e.target.value)}
            id="address"
            type={'text'}
            name="address"
            label="Wallet Address"
            />
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.address}
            </Typography>
        </FormControl>
        </CardContent>

          <CardActions>
          <Button onClick={postWithdrawal} fullWidth={true} size="large" color="primary" variant="contained">{isWithdrawing ? 'Processing....': 'Withdraw'}</Button>
          </CardActions>

        </Card>
    </SwipeableDrawer>
    
    </div>
  )
}

export default WithdrawalButton
